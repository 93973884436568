import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { createGlobalStyle } from "styled-components";
import FloatingChat from "./src/components/FloatingChat/FloatingChat";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Manrope", sans-serif;
  }

  body {
    background: #FFFFFF;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .grecaptcha-badge {
    z-index: 999;
    bottom: 100px !important;
  }
`;

export const wrapPageElement = ({ element }) => (
    <>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_RECAPTCHA_WEB_KEY}
        >
            <GlobalStyles />
            {element}
            <FloatingChat />
        </GoogleReCaptchaProvider>
    </>
);
