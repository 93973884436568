import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaMessage } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { IoCalendarNumber, IoCloseSharp } from "react-icons/io5";
import { PopupModal } from "react-calendly";
import { contactNum, calendlyUrl } from "../CommonComponents/CommonElements";

const FloatingChat = () => {
    const [isBubbleVisible, setIsBubbleVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
    const [reappearTimer, setReappearTimer] = useState(null);

    useEffect(() => {
        const randomDelay = Math.floor(Math.random() * 5000) + 2000; // Between 2-7 seconds
        const timer = setTimeout(() => setIsBubbleVisible(true), randomDelay);

        return () => clearTimeout(timer);
    }, []);

    const toggleChatBubble = () => {
        if (reappearTimer) {
            clearTimeout(reappearTimer);
            setReappearTimer(null);
        }

        if (isBubbleVisible && isExpanded) {
            setIsBubbleVisible(false);
            setIsExpanded(false);
            resetReappearBubble();
        } else {
            if (!isBubbleVisible) {
                setIsBubbleVisible(true);
            }
            setIsExpanded(true);
        }
    };

    const closeBubble = () => {
        setIsBubbleVisible(false);
        setIsExpanded(false);
        resetReappearBubble();
    };

    const resetReappearBubble = () => {
        const timer = setTimeout(() => {
            setIsBubbleVisible(true);
            setIsExpanded(false);
        }, 240000); // 4 minutes

        setReappearTimer(timer);
    };

    return (
        <div className="floating-chat">
            {/* Floating button */}
            <button className="floating-button" onClick={toggleChatBubble}>
                <i className="contact-icon">{(isExpanded && isBubbleVisible) ? <FaAngleDown /> : <FaMessage />}</i>
            </button>

            {/* Chat bubble */}
            {isBubbleVisible && (
                <div className={`chat-bubble ${isExpanded ? "expanded" : ""}`} onClick={() => !isExpanded && setIsExpanded(true)}>
                    {!isExpanded && (
                    <div className="chat-header">
                        <div className="chat-avatar avatar-img">
                            <StaticImage
                                src="../../../static/floatingChat/profile-bg.png"
                                alt="Chat Avatar"
                                imgClassName="avatar-img"
                            />
                        </div>
                        <span className="chat-title">
                            Please reach out Us. We're here to help!
                        </span>
                    </div>
                    )}

                    {/* Close button on hover */}
                    <button className="close-button-hover" onClick={closeBubble}>
                        <IoCloseSharp />
                    </button>

                    {/* Expanded content */}
                    {isExpanded && (
                        <div className="chat-content">
                            <h3>Hello, nice to see you here 👋</h3>
                            <p>Contact us via WhatsApp:</p>
                            <a href={`https://wa.me/${contactNum}`}
                               target="_blank"
                               rel="noopener noreferrer"
                               className="chat-button whatsapp-button"
                            >
                                <RiWhatsappFill className="whatsapp-icon"/>
                                Chat on WhatsApp
                            </a>
                            <p>or schedule a meeting:</p>
                            <a href="javascript:void(0)"
                               className="chat-button schedule-button"
                               onClick={() => setIsSchedulerOpen(true)}
                            >
                                <IoCalendarNumber className="whatsapp-icon" />
                                Click to schedule
                            </a>
                            <PopupModal
                                url={calendlyUrl}
                                rootElement={document.getElementById("___gatsby")}
                                pageSettings={{
                                    "backgroundColor": "ffffff",
                                    "hideEventTypeDetails": false,
                                    "hideLandingPageDetails": false,
                                    "primaryColor": "00a2ff",
                                    "textColor": "4d5055",
                                    "hideGdprBanner": true
                                }}
                                onModalClose={() => setIsSchedulerOpen(false)}
                                open={isSchedulerOpen}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FloatingChat;