import styled, { css } from "styled-components";
import { Link } from "gatsby";

export const emails = {
  contactEmail: "office@expinit.pl",
  recruitmentEmail: "recruitment@expinit.pl",
  iodEmail: "iod@expinit.pl",
  juliaEmail: "julia.karas@expinit.pl",
  olaEmail: "aleksandra.kowalska@expinit.pl",
};
export const calendlyUrl ="https://calendly.com/mariusz-kamelak/event-with-mariusz-expinit-poland-co-founder-and-cto";
export const contactNum = "+48730888650";
export const juliaNum = "";
export const olaNum = "";
export const iodDocument = "/";
export const company = {
  name: "EXPinIT & Kamelak sp. j.",
  address: "Odkryta 38b / 48",
  location: "03-140 Warszawa",
  regonNo: "520984703",
  KRS: "0000945174",
  taxNo: "5242931986",
};
export const socialMedia = {
  linkedInUrl: "https://www.linkedin.com/company/expinit/",
  fbUrl: "https://www.facebook.com/EXPinITPoland",
  xUrl: "https://www.x.com/EXPinIT_Poland",
  instUrl: "",
  mariuszXUrl: "https://twitter.com/MariuszKamelak",
  mariuszLiUrl: "https://www.linkedin.com/in/mariusz-kamelak/",
  mariuszFbUrl: "https://www.facebook.com/mariusz.kamelak",
  mariuszInstUrl: "https://www.instagram.com/mariusz.kamelak/",
  elzbietaXUrl: "",
  elzbietaLiUrl: "https://www.linkedin.com/in/el%C5%BCbieta-kamelak-793a2b170/",
  elzbietaFbUrl: "https://www.facebook.com/ela.kamelak",
  elzbietaInstUrl: "",
  juliaXUrl: "",
  juliaLiUrl: "https://www.linkedin.com/in/juliakaras/",
  juliaFbUrl: "",
  juliaInstUrl: "",
  olaXUrl: "",
  olaLiUrl: "https://www.linkedin.com/in/aleksandra-kowalska2/",
  olaFbUrl: "",
  olaInstUrl: "",
};

export const seo = {
  title: "EXPinIT | EXPerts in IT | Shaping your future together",
  description:
    "Since 2013, we have been strategically engaging our highly qualified Experts in IT - EXPinIT - filling your IT teams, leading digital transformations, facilitating project management and continuous operational maintenance of IT and Business.",
  mainImage: "/seo_logo.jpg",
  url: "https://www.expinit.pl",
};

export const contactConsent = {
  consent:
    "I am aware that the Controller of my personal data is EXPinIT & Kamelak sp. j. " +
    "and that data is processed as part of the contact form for the purpose of responding " +
    "to a question, comment or other message presented, which is a legitimate interest pursued " +
    "by the Controller in accordance with point f of Article 6(1) of GDPR/RODO, as well as I have read the",
};

export const ErrorImage = css`
  max-height: 90vh;
  max-width: 100%;
`;

export const ErrorImageWrapper = css`
  max-height: 90vh;
  max-width: 100%;
`;

export const CookiesPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 10vw;
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
  color: #1e2a3c;

  .colored {
    color: #3866c2;
  }

  & h2,
  p {
    margin: 2vh;
  }

  & p {
    font-family: "Roboto Light", sans-serif;
    font-weight: 100;
  }

  & a {
    color: #ffba49;
    text-decoration: none;
  }
`;

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-height: 100vh;

  .common-404-image {
    ${ErrorImage}
  }

  .common-404-image-wrapper {
    ${ErrorImageWrapper}
  }
`;

export const ReturnButton = styled(Link)`
  position: absolute;
  top: 70vh;
  margin: auto;
  padding: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ffba49;
  color: #1e2a3c;
  width: 200px;
  height: 35px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Roboto Mono", sans-serif;
  font-weight: 100;
  font-size: 1.2rem;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));

  &:hover {
    font-weight: 900;
    letter-spacing: 1px;
    width: 204px;
    height: 39px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.8));
    transition: all 700ms;
  }
`;

const RawToPrepared = (rawContent) => {
  return rawContent.replace(/ /g, "%20").replace(/<br\s*>/gi, "%0D%0A");
};

const RawJobConsent =
  "<br>Administrator danych<br>Administratorem Twoich danych osobowych jest: EXPinIT %26 Kamelak sp.j. z siedzibą w 03-140 Warszawa, Odkryta 38B lok 48, posiadająca NIP: 5242931986.<br>" +
    "<br>Zgoda na przetwarzanie danych<br>Wyrażam zgodę na przetwarzanie przez EXPinIT %26 Kamelak sp.j., z siedzibą w Warszawa, Odkryta 38B lok 48, danych osobowych zawartych w moim zgłoszeniu w celu przeprowadzenia rekrutacji na stanowisko wskazane w ogłoszeniu. " +
    "<br>Zgadzam się na przetwarzanie danych osobowych dla celów rekrutacji przez dwa lata.<br>" +
    "<br>Zgoda na przetwarzanie danych na cele przyszłych rekrutacji<br>Jeśli chcesz wyrazić zgodę na udział również w innych rekrutacjach EXPinIT %26 Kamelak sp.j. zostaw zdanie:<br>" +
    "<br>Wyrażam zgodę na przetwarzanie przez EXPinIT %26 Kamelak sp.j., z siedzibą w 03-140 Warszawa, Odkryta 38B lok 48, danych osobowych zawartych w moim zgłoszeniu także dla celów przyszłych rekrutacji. " +
    "<br>Zgadzam się na przetwarzanie danych osobowych dla celów przyszłych rekrutacji przez dwa lata.";

const RawMessagePlaceholder =
  "Imię,Nazwisko: <br>Oczekiwana stawka:<br>Dyspozycyjność od:<br>Załącznik: ( Prosze dodać CV )<br>";

const PreparedJobConsent = RawToPrepared(RawJobConsent);

const PreparedMessage = RawToPrepared(RawMessagePlaceholder);

export function GetMailToContent(jobOfferData) {
  let mailTo = `mailto:${emails.recruitmentEmail}`;
  if (jobOfferData.title !== undefined) {
    const subject =
      "Aplikacja%20na%20ogłoszenie%20-%20" +
      jobOfferData.title.replace(/ /g, "%20");
    const jobURL = seo.url + jobOfferData.jobLink;
    const body = `Oferta:%20${jobURL}%0D%0A${PreparedMessage}${PreparedJobConsent}`;
    mailTo = `mailto:${emails.recruitmentEmail}?subject=${subject}&body=${body}`;
  }
  return mailTo;
}

export function GetSimpleMailToContent() {
  const subject = "Kontakt%20w%20sprawie%20oferty%20";
  const body = `${PreparedMessage}${PreparedJobConsent}`;

  return `mailto:${emails.recruitmentEmail}?subject=${subject}&body=${body}`;
}
